/**
 * LIVEアイコンのリンク先を場別に制御
 * @params String vc   競輪場コード
 * @params Bool   isSp スマホ判定
 * @return String url  リンク先URL
 */
export default function getKeirinLiveUrl(vc, isSp) {
  vc = vc.toString();
  var url = "";
  url = isSp === true ? "http://keirin.jp/sp/top" : "http://keirin.jp/pc/top";
  return url;
}
