/**
 * オートダイジェスト動画のリンク先を場別に制御
 * @params String vel_code   オート場コード
 * @return String url  リンク先URL
 * 場コード 1 byte
 * ( 第6章 コード表 場コード)
 * 001 : 船 橋オート
 * 002 : 川 口オート
 * 012 : 川 口オート2
 * 003 : 伊勢崎オート
 * 004 : 浜 松オート
 * 005 : 飯 塚オート
 * 006 : 山 陽オート
 * 007 : 共有場外,GambooBET,オッズパーク,チャリロト
 * 008 : センタ(キャッシュレス投票),電話投票,インターネット投票
 * 009 : センタ
 *
 * 001 船橋はすでに廃止
 *
 * sp: http://sp-auto.digi-c.com/autorace/_definst_/kawaguchi/2018/kawaguchi_20180719_02/playlist.m3u8
 * pc: http://autorace.jp/netstadium/Ondemand/asx/kawaguchi/2018-07-19_2
 *
 */

export default function getAutoDigestUrl(open_day, vel_code, race_num, isSp) {
  vel_code = vel_code.toString();
  open_day = open_day ? open_day.toString() : "";
  race_num = race_num ? race_num.toString() : "";

  var velName = {
    "002": "kawaguchi",
    "012": "kawaguchi",
    "003": "isesaki",
    "004": "hamamatsu",
    "005": "iizuka",
    "006": "sanyou",
  };

  var baseUrl;
  var additionalPath;

  if (isSp) {
    baseUrl =
      open_day && race_num
        ? "http://sp-auto.digi-c.com/autorace/_definst_/"
        : "http://autorace.jp/netstadium/Recent/";

    var yyyy = open_day.slice(0, 4);
    var yyyymmdd = open_day.replace(/-/g, "");
    var padding_race_num = race_num.length > 2 ? race_num : "0" + race_num;

    additionalPath =
      open_day && race_num
        ? "/" +
          yyyy +
          "/" +
          velName[vel_code] +
          "_" +
          yyyymmdd +
          "_" +
          padding_race_num +
          "/playlist.m3u8"
        : "?is_sp=1";
  } else {
    baseUrl =
      open_day && race_num
        ? "http://autorace.jp/netstadium/Ondemand/asx/"
        : "http://autorace.jp/netstadium/Recent/";
    additionalPath =
      open_day && race_num ? "/" + open_day + "_" + race_num : "";
  }

  var url = "";

  if (vel_code.match(/001|002|012|003|004|005|006/)) {
    switch (vel_code) {
      case "002":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
      case "012":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
      case "003":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
      case "004":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
      case "005":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
      case "006":
        url = baseUrl + velName[vel_code] + additionalPath;
        break;
    }
  } else {
    url = "http://autorace.jp";
  }
  return url;
}
