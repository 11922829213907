import Vue from "vue/dist/vue.esm";
import moment from 'moment/moment';
// polyfill
import "./polyfill.js";
// ユーザーのUIの状態クラス
import UsageHistory from "./class_usage_history.js";

// サイト全体共通component
import Modal from "./pc/base_modal.vue";
import UserModal from "./pc/base_user_modal.vue";

// 競輪 場ごとのLIVEリンク便利関数
import getKeirinLiveUrl from "./handle_keirin_live_link.js";

// オート 場ごとのLIVEリンク便利関数
import getAutoLiveUrl from "./handle_auto_live_link.js";

// オート 場ごとのダイジェストリンク便利関数
import getAutoDigestUrl from "./handle_auto_digest_link.js";

const axios = require('axios');

window.chari = window.chari || {};
window.cc = window.cc || {};
window.cuc = window.cuc || {};

cc.Modal = Vue.extend({
  data() {
    return {
      modalData: {
        show: false,
        content: "",
        callback: "",
        isAlert: false,
      },
    };
  },
  components: { Modal },
  methods: {
    openModal: function(text, fn, isAlert) {
      this.modalData.content = text;

      if (fn) {
        this.modalData.callback = fn;
      } else {
        this.modalData.callback = function() {};
      }

      if (isAlert) {
        this.modalData.isAlert = true;
      } else {
        this.modalData.isAlert = false;
      }

      this.modalData.show = true;
    },
    closeModal: function(command) {
      if (command == "ok") {
        this.modalData.callback();
      }
      // modalData初期化
      this.modalData = {
        show: false,
        content: "",
        callback: null,
        isAlert: false,
      };
    },
  },
});

cuc.UserModal = Vue.extend({
  data() {
    return {
      userModalData: {
        show: false,
        content: "",
        callback: "",
        isAlert: false,
      },
    };
  },
  components: { UserModal },
  methods: {
    openUserModal: function(userArticle, fn, isAlert) {
      axios.put("/api/user_article/status", {
        user_article: userArticle
      })
        .catch(error => console.log(error));

      this.userModalData.content = userArticle;

      if (fn) {
        this.userModalData.callback = fn;
      } else {
        this.userModalData.callback = function() {};
      }

      if (isAlert) {
        this.userModalData.isAlert = true;
      } else {
        this.userModalData.isAlert = false;
      }

      this.userModalData.show = true;
    },
    closeUserModal: function(command) {
      if (command == "ok") {
        this.userModalData.callback();
      }
      // modalData初期化
      this.userModalData = {
        show: false,
        content: "",
        callback: null,
        isAlert: false,
      };
    },
  },
});

//
// chari constants
//
chari.const = {
  // オート七掛けの購入UIの処理中で数値、文字列どちらでもbet_typeを利用できるように
  // 抽出するときはutilの中で定義した関数を使う
  // from chariloto-common/lib/chariloto/common.rb
  AUTO_BET_TYPE: {
    tanshou: 1,
    fukushou: 2,
    nirentan: 3,
    nirenfuku: 4,
    sanrentan: 5,
    sanrenfuku: 6,
    wide: 7,
  },
  AUTO_BET_TYPE_LABEL: {
    tanshou: "単勝",
    fukushou: "複勝",
    nirentan: "2連単",
    nirenfuku: "2連複",
    sanrentan: "3連単",
    sanrenfuku: "3連複",
    wide: "ワイド",
  },
  // 競輪 bet_type
  // from chariloto-common/lib/chariloto/common.rb
  KEIRIN_BET_TYPE: {
    niwakutan: 1,
    niwakufuku: 2,
    nirentan: 3,
    nirenfuku: 4,
    sanrentan: 5,
    sanrenfuku: 6,
    wide: 7,
  },
  KEIRIN_BET_TYPE_LABEL: {
    niwakutan: "2枠単",
    niwakufuku: "2枠複",
    nirentan: "2車単",
    nirenfuku: "2車複",
    sanrentan: "3連単",
    sanrenfuku: "3連複",
    wide: "ワイド",
  },
  // ruby 側の定数をそのまま
  KEIRIN_SALE_STATUS: {
    unregistered: 0,
    pre_cancel: 1,
    after_cancel: 3,
    on_sale: 7,
  },
  // 以下はjsだけで持っている定数
  // 七掛けの投票scriptを流用するために用意
  GAME_TYPE: {
    keirin: 1,
    autorace: 2,
  },
  AUTO_VOTE_TYPE: ["normal", "detail", "oddsTable", "oddsSort"],
  KEIRIN_VOTE_TYPE: [
    "normal",
    "detail",
    "oddsTable",
    "oddsSort",
    "prediction",
    "predictionSort",
  ],
  KEIRIN_BOX_TYPE: {
    dokanto: 1,
    dokanto_4two: 2,
    chariloto: 3,
    chariloto_select: 4,
    chariloto_5: 5,
    chariloto_3: 6,
    basic: 8,
  },
  KEIRIN_BOX_TYPE_LABEL: {
    dokanto: "Dokanto7",
    dokanto_4two: "Dokanto4two",
    chariloto: "チャリロト",
    chariloto_select: "チャリロト・セレクト",
    chariloto_5: "チャリロト５",
    chariloto_3: "チャリロト３",
  },

  // sesionStorage key名
  STORAGE_KEY: {
    keirin: {
      savedCart: "keirinSavedCart",
      voteOrders: "keirinVoteOrders",
    },
    autorace: {
      savedCart: "autoSavedCart",
      voteOrders: "autoVoteOrders",
    },
    tmpBackUrl: "tmpBackUrl", // 投票画面など戻らなきゃいけないURL
  },

  KEIRIN_SETTING: {
    unitPrice: 100,
  },

  AUTO_SETTING: {
    unitPrice: 100,
  },
  // keirin box のステータス
  KEIRIN_SMART_STATUS: {
    open: 1, // 開催中
    finish: 2, // 終了
    cancel: 3, // 中止
  },
  // auto box のステータス
  AUTO_SMART_STATUS: {
    open: 1, // 開催中
    finish: 2, // 終了
    cancel: 3, // 中止
  },
};

//
// utility function
//

chari.util = {
  // 入金完了画面から投票票画面に戻る必要がある場合に使う
  tmpBackUrl: {
    set: () => {
      sessionStorage.setItem(
        chari.const.STORAGE_KEY.tmpBackUrl,
        document.location
      );
    },
    get: () => {
      return sessionStorage.getItem(chari.const.STORAGE_KEY.tmpBackUrl);
    },
    remove: () => {
      sessionStorage.removeItem(chari.const.STORAGE_KEY.tmpBackUrl);
    },
  },
  // Date, function, undefined will be removed.
  deepCopy: function(obj) {
    var copy = JSON.parse(JSON.stringify(obj));

    return copy;
  },
  arrayRemoveRepeat: function(arr1, arr2) {
    var arr3 = arr1.concat(arr2);

    var result = arr3.filter(function(x, i, a) {
      return a.indexOf(x) === i;
    });

    return result;
  },
  // ２つの配列を比較し重複していない要素だけで新しい配列を生成し返す
  arrayDiff: function(arr1, arr2) {
    var diff = [];

    for (var i = 0; i < arr1.length; i++) {
      if (arr2.indexOf(arr1[i]) === -1) {
        diff.push(arr1[i]);
      }
    }

    for (var j = 0; j < arr2.length; j++) {
      if (arr1.indexOf(arr2[j]) === -1) {
        diff.push(arr2[j]);
      }
    }

    return diff;
  },
  // 配列の重複した要素を間引き新しい配列として返す
  uniqueElmArray: function(arr) {
    return arr.filter(function(x, i, a) {
      return a.indexOf(x) === i;
    });
  },
  // ページ内のすべてのlinkをbindし、遷移しても良いか確認する
  confirmLink: function(msg, charica_charge_url, skip_path) {
    var links = document.getElementsByTagName("a");

    for (var i = 0; i < links.length; i++) {
      links[i].addEventListener("click", function(e) {
        e.preventDefault();

        var targetLink = this.getAttribute("href");

        if (targetLink == skip_path) {
          window.location.href = targetLink;
          return false;
        }

        if (targetLink == charica_charge_url) {
          open(targetLink, '_blank');
        }
        else if (targetLink == 'https://ws.formzu.net/fgen/S16680915/') {
          open(targetLink, '_blank');
        }
        else {
          cc.modal.openModal(msg, function() {
            location.href = targetLink;
          });
        }
        return false;
      });
    }
  },

  hashToQuery: function(hash) {
    var query = [];
    for (var i in hash) {
      query.push(i + "=" + encodeURIComponent(hash[i]));
    }
    return query.join("&");
  },

  // ISO8601形式の時刻文字列を加工してHH:MM形式の文字列に変換
  // e.g. "2000-01-01T18:00:00.000+09:00" => "18:00"
  extractHoursMinutes: function(date) {
    var d = new Date(date);
    var hh = ("0" + d.getHours()).slice(-2);
    var mm = ("0" + d.getMinutes()).slice(-2);
    return hh + ":" + mm;
  },

  adjustDate: function(date) {
    var d = new Date(date);
    return d.toLocaleString();
  },

  // ミリ秒を時分へ変換する
  convertMsToDate: function(ms) {
    var minus = "";

    if (ms < 0) {
      ms = ms * -1;
      minus = "-";
    }

    var h = parseInt(String(Math.floor(ms / 3600000) + 100).substring(1));
    var m = parseInt(
      String(Math.floor((ms - h * 3600000) / 60000) + 100).substring(1)
    );
    // var s = String((Math.round(ms - h * 3600000 - m * 60000)+ 100).substring(1);

    var message = "";
    if (h > 0) {
      message = h + "時間";
    }
    message = minus + message + m + "分";
    return message;
  },
  // BET_TYPEの変換
  // 第一引数でGAME_TYPEを指定し
  // 第二引第二引数で変換元の値を指定
  // valueが数値なら文字列、valueが文字列なら数値を返す
  swapBetType: function(type, value) {
    function returner(obj) {
      if (typeof value === "number") {
        return Object.keys(obj)
          .map(function(key) {
            return obj[key] === value ? key : "";
          })
          .join("");
      } else if (typeof value === "string") {
        return obj[value];
      }
    }

    if (type === "keirin" || type === 1) {
      var useBetTypes = chari.const.KEIRIN_BET_TYPE;
      return returner(useBetTypes);
    } else if (type === "autorace" || type === 2) {
      var useBetTypes = chari.const.AUTO_BET_TYPE;
      return returner(useBetTypes);
    }
  },
  getBetTypeLabel: function(gametype, bet) {
    if (gametype == 1 || gametype == "keirin") {
      var betTypes = chari.const.KEIRIN_BET_TYPE;
    } else if (gametype == 2 || gametype == "autorace") {
      var betTypes = chari.const.AUTO_BET_TYPE;
    }

    var bet;
    if (typeof bet === "number") {
      bet = bet;
    } else if (typeof bet === "string") {
      bet = chari.util.swapBetType(gametype, bet);
    }

    var label = [];
    for (var key in betTypes) {
      label.push("bet_" + key);
    }

    return label[bet - 1];
  },
  // enum の valueを返す
  getEnumVal: function(param, enumObj) {
    if (isNaN(param)) {
      return enumObj[param];
    } else {
      return parseInt(param);
    }
  },
  // 最初の一文字を大文字に変換
  capitalizeFirstLetter: function(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  // 全てを大文字に変換
  capitalizeAll: function(str) {
    var text = str ? str : "";

    return text.toUpperCase();
  },
  // ナイター／ミッドナイト区分の変換
  adjustNighterType: function(nighter_race_type) {
    if (nighter_race_type == "morning") {
      return "Mo";
    } else if (nighter_race_type == "night") {
      return "N";
    } else if (nighter_race_type == "midnight") {
      return "MD";
    } else {
      return "";
    }
  },
  // 数値の有効数字を指定する 末尾が0の場合省略される
  // 整形する数値を第一引数
  // 使いたい小数点桁数を第二引数で指定
  // 第三引数は切り上げか切り捨てかboolean
  adjustNum: function(num, n, isCeil) {
    if (isCeil) {
      return Math.ceil(num * Math.pow(10, n)) / Math.pow(10, n);
    } else {
      return Math.floor(num * Math.pow(10, n)) / Math.pow(10, n);
    }
  },
  // 数値を日本円表示に変換する
  // 兆円以上は対応していないのでそのまま数値を返す
  convertAmountKanji: function(num) {
    var num = num + "";

    if (num.length <= 16 && !num.match(/e/g)) {
      // 実用範囲内の万、億、兆までとりあえず対応
      var nums = num.split("").reverse();
      var index = 0;
      var digit = 4;
      var results = [];
      var unitJp = ["万", "億", "兆"];

      while (index < nums.length) {
        var result = nums.slice(index, index + digit).reverse();
        result = parseInt(result.join(""));

        if (result === 0) {
          if (nums.length < 5) {
            // 5桁未満の場合は0を残す
            result = "0";
          } else {
            result = "";
          }
        } else {
          if (results.length > 0) {
            if (unitJp[results.length - 1]) {
              result = result + unitJp[results.length - 1];
            } else {
              result =
                num.slice(0, -1 * digit * unitJp.length) +
                unitJp[unitJp.length - 1];
            }
          }
        }

        results.push(result);
        index = index + digit;
      }

      results = results.reverse().join("");
      return results;
    } else {
      return num;
    }
  },
  gameIsKeirin: function(gameType) {
    if (gameType == chari.const.GAME_TYPE.keirin) {
      return true;
    } else {
      return false;
    }
  },
  // 出目のハイフンを表示用にイコールに変更する
  convertHyphenToEqual: function(deme) {
    var str = deme.toString();

    return str.replace(/-/g, "=");
  },
  // 賭式が複式かの判定、表示でdemeデータのハイフンをイコールに変更する時に使う
  // 競輪かオートかの情報が引数で必要
  isFukushiki: function(bet_type, gameType) {
    if (chari.util.gameIsKeirin(gameType)) {
      var obj = chari.const.KEIRIN_BET_TYPE;
    } else {
      var obj = chari.const.AUTO_BET_TYPE;
    }
    var type = parseInt(bet_type)
      ? parseInt(bet_type)
      : obj[bet_type]
      ? obj[bet_type]
      : "";
    var fukushouKeyList = [
      "niwakufuku",
      "fukushou",
      "nirenfuku",
      "sanrenfuku",
      "wide",
    ];
    var arr = [];

    for (var i = 0; i < fukushouKeyList.length; i++) {
      arr.push(obj[fukushouKeyList[i]]);
    }

    if (arr.indexOf(type) > -1) {
      return true;
    } else {
      return false;
    }
  },
  // 現在URLのparameter queryをhashにして返す
  getQueryStrings: function() {
    if (window.location.search) {
      var queries = window.location.search.slice(1).split("&");

      var hash = {};
      for (var i = 0; i < queries.length; i++) {
        var query = queries[i].split("=");
        hash[query[0]] = query[1];
      }

      return hash;
    }
  },
  // objectが空かどうかの判定
  hasProperty: function(obj) {
    if (Object.keys(obj).length > 0) {
      return true;
    } else {
      return false;
    }
  },
  scrollToElm: function(selector) {
    if (selector.index() > 0) {
      window.scrollTo(0, selector.offset().top);
    }
  },
  // objのvalueからkeyを取得
  getKey: function(obj, val) {
    return Object.keys(obj).find(function(el) {
      return obj[el] == val;
    });
  },
  getWithKeyChainStr: function(obj, KeyChainStr) {
    var keys = KeyChainStr.split(".");
    var val = obj;
    var key;
    while (keys.length > 0) {
      key = keys.shift();
      val = val[key];
    }
    return val;
  },
  setWithKeyChainStr: function(obj, KeyChainStr, setVal) {
    var keys = KeyChainStr.split(".");
    var val = obj;
    var key;

    digObj: while (keys.length > 0) {
      key = keys.shift();

      if (keys.length === 0) break digObj;
      val = val[key];
    }
    val[key] = setVal;
  },
  checkUserAgent: function(str) {
    var toString = Object.prototype.toString;
    var ua = navigator.userAgent;
    var arr = [];

    if (typeof str === "string") {
      arr.push(str);
    } else if (toString.call(str).match(/Array/g)) {
      arr = str;
    } else {
      console.log(
        "checkUserAgent関数の引数にはarrayかstringを指定してください"
      );
      return false;
    }

    return arr.some(function(e) {
      return ua.indexOf(e) > -1;
    });
  },
  switchViewingType: function(type) {
    document.cookie = "viewing_type=" + type + "; path=/";
    location.reload();
  },
  getLiveUrl: function(vel_code, isSp, gameType) {
    if (!chari.util.gameIsKeirin(gameType)) {
      return getAutoLiveUrl(vel_code, isSp);
    } else {
      return getKeirinLiveUrl(vel_code, isSp);
    }
  },
  getDigestUrl: function(open_day, vel_code, race_num, gameType, isSp) {
    if (!chari.util.gameIsKeirin(gameType)) {
      return getAutoDigestUrl(open_day, vel_code, race_num, isSp);
    } else {
      return "http://keirin.jp/";
    }
  },
};

chari.api = {
  request: function(param, cb) {
    param.dataType = "json";
    param.headers = {
      "X-CSRF-Token": $("meta[name=csrf-token]").attr("content"),
    };
    $.ajax(param)
      .done(function(data) {
        if (cb) cb(data);
      })
      .fail(function(req, res) {
        if (cb) cb({ error: 1 });
      });
  },

  get: function(url, params, cb) {
    if (params) url += "?" + chari.util.hashToQuery(params);
    chari.api.request({ method: "GET", url: url }, cb);
  },

  // for json only
  post: function(url, params, cb) {
    var options = {
      url: url,
      method: "POST",
      contentType: "application/json",
      data: JSON.stringify(params),
    };
    chari.api.request(options, cb);
  },

  getKeirinJushouPredictions: function(params, cb) {
    chari.api.get("/api/keirin/predictions/jushou", params, function(res) {
      cb(res);
    });
  },

  getKeirinKujiPredictions: function(params, cb) {
    chari.api.get("/api/keirin/predictions/kuji", params, function(res) {
      cb(res);
    });
  },

  getKeirinJushouVotings: function(params, cb) {
    chari.api.get("/api/keirin_jushous/votings", params, function(res) {
      cb(res);
    });
  },

  getKeirinBoxes: function(params, cb) {
    chari.api.get("/api/keirin/boxes", params, function(res) {
      cb(res);
    });
  },

  getAutoBoxes: function(params, cb) {
    chari.api.get("/api/auto/boxes", params, function(res) {
      cb(res);
    });
  },

  getKeirinRaceInfo: function(params, cb) {
    chari.api.get("/api/keirin/race_info", params, function(res) {
      cb(res);
    });
  },

  getAutoRaceInfo: function(params, cb) {
    chari.api.get("/api/auto/race_info", params, function(res) {
      cb(res);
    });
  },

  getKeirinPrediction: function(params, cb) {
    chari.api.get("/api/keirin/predictions/nanakake_narabi", params, function(
      res
    ) {
      cb(res);
    });
  },

  postOrderUpdateNanakakeKeirin: function(params, cb) {
    chari.api.post("/api/keirin/update_order_data", params, function(res) {
      cb(res);
    });
  },

  postOrderUpdateNanakakeAuto: function(params, cb) {
    chari.api.post("/api/auto/update_order_data", params, function(res) {
      cb(res);
    });
  },
};

// ユーザーの使用したUIの状態記憶・復元class
chari.UsageHistory = UsageHistory;

//
// DOM selector driven function
//

// 2019/05/14
// $(document).ready(function() {
//   // date picker
//   $('.js-datepicker').datepicker({
//     showOn: 'both',
//     dateFormat: "yymmdd",
//     buttonImageOnly: true,
//     buttonImage: "/img/icon/ico_datepicker.png",
//     language: 'ja'
//   });
// });

// Vue Global settings
// すべてのVueコンポーネントにマージされるmixin
Vue.mixin({
  // moment.jsの有効化
  methods: {
    moment: function(a, b, c, d) {
      return moment(a, b, c, d);
    },
  },
  // app.js内の共通scriptをvuejsで使えるように
  data: function() {
    return {
      chari: chari,
    };
  },
});
