<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self.prevent="$emit('cancel')">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body">{{modalData.content}}</slot>
          </div>
          <div class="modal-footer clearfix">
            <slot name="footer">
              <button class="fl-left btn btn-default btn-slim w45per" @click.prevent="$emit('cancel')" v-if="!modalData.isAlert">
                キャンセル
              </button>
              <slot name="confirm">
                <button class="fl-right btn btn-info btn-slim w45per" @click.prevent="$emit('ok')">
                  OK
                </button>
              </slot>
            </slot>
          </div>
        </div><!-- .modal-container -->
      </div><!-- .modal-wrapper -->
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: ['modalData'],
  methods: {
    close() {
      modalData.show = false;
    }
  }
}
</script>
