<template>
  <transition name="user-modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self.prevent="$emit('cancel')">
        <div class="modal-user-container">
          <div class="modal-user-header">
            <slot name="header">
              <div class="heading heading-filled">
                <h3 class="heading-title">
                  <i aria-hidden="true" class="ico ico-news text-base mr5 mr5"></i>
                  <span class="vam text-bold">メンバーニュース</span>
                </h3>
                <div class="heading-tool">
                  <a href="/user_articles" class="btn btn-arrow-right btn-base fl-right">ニュース一覧</a>
                </div>
              </div>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <ul v-for="article in userModalData.content" class="list-border-bottom list-border-bottom-wide">
                <li class="mb10">
                  <span>{{ moment(article.start_at, moment.ISO_8601).format('YYYY/MM/DD HH:mm') }}</span>
                  <br>
                  <span v-if="recent(article.start_at)" class="tag tag-sky tag-small"> NEW </span>
                  <span v-if="article.important == 1" class="tag tag-hot tag-small vam"> 重要 </span>
                  <span v-html="article.message"></span>
                </li>
              </ul>
            </slot>
          </div>
          <div class="modal-footer clearfix">
            <slot name="footer">
              <slot name="confirm">
                <button class="fl-right btn btn-info btn-slim w45per" @click.prevent="$emit('ok')">
                  閉じる
                </button>
              </slot>
            </slot>
          </div>
        </div><!-- .modal-container -->
      </div><!-- .modal-wrapper -->
    </div>
  </transition>
</template>

<script>
import moment from 'moment/moment';

export default {
  userArticle() {
    return {}
  },
  props: ['userModalData'],
  methods: {
    close() {
      userModalData.show = false;
    },
    recent: function(date) {
      var aday = moment().subtract(7, 'days');
      var bday = moment(date);
      return bday.isAfter(aday);
    }
  }
}
</script>
