/**
 * LIVEアイコンのリンク先を場別に制御
 * @params String vc   オート場コード
 * @return String url  リンク先URL
 * 場コード 1 byte
 * ( 第6章 コード表 場コード)
 * 001 : 船 橋オート
 * 002 : 川 口オート
 * 012 : 川 口オート2
 * 003 : 伊勢崎オート
 * 004 : 浜 松オート
 * 005 : 飯 塚オート
 * 006 : 山 陽オート
 * 007 : 共有場外,GambooBET,オッズパーク,チャリロト
 * 008 : センタ(キャッシュレス投票),電話投票,インターネット投票
 * 009 : センタ
 *
 * 001 船橋はすでに廃止
 */

export default function getAutoLiveUrl(vc, isSp) {
  vc = vc.toString();
  var url = "";

  if (vc.match(/001|002|012|003|004|005|006/)) {
    switch (vc) {
      case "002":
        url = isSp
          ? "https://autorace.jp/race_info/Live/kawaguchi"
          : "https://autorace.jp/race_info/Live/kawaguchi";
        break;
      case "012":
        url = isSp
          ? "https://autorace.jp/race_info/Live/kawaguchi"
          : "https://autorace.jp/race_info/Live/kawaguchi";
        break;
      case "003":
        url = isSp
          ? "https://autorace.jp/race_info/Live/isesaki"
          : "https://autorace.jp/race_info/Live/isesaki";
        break;
      case "004":
        url = isSp
          ? "https://autorace.jp/race_info/Live/hamamatsu"
          : "https://autorace.jp/race_info/Live/hamamatsu";
        break;
      case "005":
        url = isSp
          ? "https://autorace.jp/race_info/Live/iizuka"
          : "https://autorace.jp/race_info/Live/iizuka";
        break;
      case "006":
        url = isSp
          ? "https://autorace.jp/race_info/Live/sanyou"
          : "https://autorace.jp/race_info/Live/sanyou";
        break;
    }
  } else {
    url = "http://autorace.jp";
  }

  return url;
}
