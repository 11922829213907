/**
 * TOPページや投票画面でユーザが最後に選択していた
 * UIの状態制御class
 *
 * @export
 * @class UsageHistory
 */
export default class UsageHistory {
  constructor() {
    this.init();
  }

  // 初期状態
  init() {
    this._topJushouTab = 3; // TOPページの重勝タブの前回開いていたもの
    this._topNanakakeTab = 1; // TOPページの７賭けタブの前回開いていたもの 競輪 or オート
    this._autoLastBetTypes = []; // within AUTO_BET_TYPES
    this._keirinLastBetTypes = []; // within KEIRIN_BET_TYPES
    this._keirinOddsLastBetType = "bet_nirentan"; // within KEIRIN_BET_TYPES
    this._autoOddsLastBetType = "bet_nirentan"; // within AUTO_BET_TYPES

    this.loadStorage();

    // NOTE: saveStorageは各プロパティの変更時に実行させるべき？
    window.addEventListener("beforeunload", () => {
      this.saveStorage();
    });
  }

  // クラス変数
  // TODO: chari.const から要継承？
  static get CONST() {
    return {
      TOP_JUSOU_KEY: "topJushouTab",
      TOP_NANAKAKE_KEY: "topNanakakeTab",
      AUTO_LAST_BET_KEY: "autoLastBets",
      KEIRIN_LAST_BET_KEY: "keirinLastBets",
      KEIRIN_LAST_ODDS_BET_KEY: "keirinOddsLastBet",
      AUTO_LAST_ODDS_BET_KEY: "autoOddsLastBet",
      AUTO_BET_TYPES: [
        "bet_tanshou",
        "bet_fukushou",
        "bet_nirentan",
        "bet_nirenfuku",
        "bet_sanrenfuku",
        "bet_sanrentan",
        "bet_wide",
      ],
      KEIRIN_BET_TYPES: [
        "bet_niwakufuku",
        "bet_niwakutan",
        "bet_nirenfuku",
        "bet_nirentan",
        "bet_sanrenfuku",
        "bet_sanrentan",
        "bet_wide",
      ],
    };
  }

  // setter
  set topJushouTab(num) {
    this._topJushouTab = num >= 1 && num <= 6 ? num : 1;
  }

  set topNanakakeTab(num) {
    this._topNanakakeTab = num === 1 || num === 2 ? num : 1;
  }

  set keirinOddsLastBetType(betType) {
    this._keirinOddsLastBetType = this.constructor.CONST.KEIRIN_BET_TYPES.some(
      model => {
        return betType === model;
      }
    )
      ? betType
      : "bet_nirentan";
  }

  set autoOddsLastBetType(betType) {
    this._autoOddsLastBetType = this.constructor.CONST.AUTO_BET_TYPES.some(
      model => {
        return betType === model;
      }
    )
      ? betType
      : "bet_nirentan";
  }

  // 値のvalidation込み
  setBetTypes(models, bet) {
    let result = [];

    if (Array.isArray(bet)) {
      result = bet.filter(function(e, i, arr) {
        return (
          arr.indexOf(e) === i &&
          models.some(model => {
            return e === model;
          })
        );
      });
    } else {
      if (
        models.some(model => {
          return bet === model;
        })
      ) {
        result.push(bet);
      }
    }

    return result;
  }

  setKeirinLastBetTypes(bet) {
    this._keirinLastBetTypes = this.setBetTypes(
      this.constructor.CONST.KEIRIN_BET_TYPES,
      bet
    );
  }

  setAutoLastBetTypes(bet) {
    this._autoLastBetTypes = this.setBetTypes(
      this.constructor.CONST.AUTO_BET_TYPES,
      bet
    );
  }

  // getter
  get topJushouTab() {
    return this._topJushouTab;
  }

  get topNanakakeTab() {
    return this._topNanakakeTab;
  }

  get keirinLastBetTypes() {
    return this._keirinLastBetTypes;
  }

  get autoLastBetTypes() {
    return this._autoLastBetTypes;
  }

  get keirinOddsLastBetType() {
    return this._keirinOddsLastBetType;
  }

  get autoOddsLastBetType() {
    return this._autoOddsLastBetType;
  }

  saveStorage() {
    localStorage.setItem(
      this.constructor.CONST.TOP_JUSOU_KEY,
      this.topJushouTab
    );
    localStorage.setItem(
      this.constructor.CONST.TOP_NANAKAKE_KEY,
      this.topNanakakeTab
    );
    localStorage.setItem(
      this.constructor.CONST.KEIRIN_LAST_ODDS_BET_KEY,
      this.keirinOddsLastBetType
    );
    localStorage.setItem(
      this.constructor.CONST.AUTO_LAST_ODDS_BET_KEY,
      this.autoOddsLastBetType
    );
    localStorage.setItem(
      this.constructor.CONST.AUTO_LAST_BET_KEY,
      JSON.stringify(this.autoLastBetTypes)
    );
    localStorage.setItem(
      this.constructor.CONST.KEIRIN_LAST_BET_KEY,
      JSON.stringify(this.keirinLastBetTypes)
    );
  }

  // localStorageからデータを復元しエラーがある場合はインスタンスとlocalStorageを初期化
  loadStorage() {
    try {
      this.topJushouTab = parseInt(
        localStorage.getItem(this.constructor.CONST.TOP_JUSOU_KEY),
        10
      );
      this.topNanakakeTab = parseInt(
        localStorage.getItem(this.constructor.CONST.TOP_NANAKAKE_KEY),
        10
      );
      this.autoOddsLastBetType = localStorage.getItem(
        this.constructor.CONST.AUTO_LAST_ODDS_BET_KEY
      );
      this.keirinOddsLastBetType = localStorage.getItem(
        this.constructor.CONST.KEIRIN_LAST_ODDS_BET_KEY
      );
      this.setKeirinLastBetTypes(
        JSON.parse(
          localStorage.getItem(this.constructor.CONST.KEIRIN_LAST_BET_KEY)
        )
      );
      this.setAutoLastBetTypes(
        JSON.parse(
          localStorage.getItem(this.constructor.CONST.AUTO_LAST_BET_KEY)
        )
      );
    } catch (e) {
      this.constructor.clearStorage();
      this.init();
    }
  }

  static clearStorage() {
    localStorage.removeItem(this.CONST.TOP_JUSOU_KEY);
    localStorage.removeItem(this.CONST.TOP_NANAKAKE_KEY);
    localStorage.removeItem(this.CONST.KEIRIN_LAST_BET_KEY);
    localStorage.removeItem(this.CONST.AUTO_LAST_BET_KEY);
    localStorage.removeItem(this.CONST.KEIRIN_LAST_ODDS_BET_KEY);
    localStorage.removeItem(this.CONST.AUTO_LAST_ODDS_BET_KEY);
  }
}
